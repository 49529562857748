body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

h3 {
  padding-bottom: 20px;
  font-weight: 700;
  font-size: 25px;
  /* font-family: "Aclonica", sans-serif; */
}

h6 {
  font-size: 20px;
  font-weight: 600;
}

@media only screen and (max-width: 600px) {
  .otp-diff.differ-0 input {
    margin: unset !important;
    height: 30px !important;
    width: 30px !important;
  }

  .footer {
    height: 495px !important;
  }
}


.owl-prev span{
  background: #080874;
  padding: 8px;
  color: white; 
}
.owl-next span{
  background: #080874;
  padding: 8px;
  color: white; 
}

/* Add these styles to your CSS file */
.pagination {
  display: flex;
  list-style: none;
  padding: 0;
}

.pagination li {
  margin: 0 5px;
  border: 1px solid #080874;
  background-color: #f8f9fa;
  /* Light background color */
  border-radius: 4px;
}

.pagination li a {
  padding: 5px 10px;
  text-decoration: none;
  color: #080874;
  display: inline-block;
}

.pagination li.active a {
  background-color: #080874;
  color: white;
}

.pagination li a:hover {
  background-color: #080874;
  color: white;
}

.break-me {
  margin: 0 5px;
  border: 1px solid #080874;
  background-color: #f8f9fa;
  border-radius: 4px;
  padding: 5px 10px;
}
.select-search {
  width: 250px;
  position: relative;
  font-family: "Nunito Sans", sans-serif;
  box-sizing: border-box;
  margin: 20px 20px 20px 0px;
}

.select-search *,
.select-search *::after,
.select-search *::before {
  box-sizing: inherit;
}

/**
 * Value wrapper
 */
.select-search__value {
  position: relative;
  z-index: 1;
}

.select-search__value::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: calc(50% - 9px);
  right: 19px;
  width: 11px;
  height: 11px;
}

/**
 * Input
 */
.select-search__input {
  display: block;
  height: 36px;
  width: 100%;
  padding: 0 16px;
  background: #fff;
  border: 1px solid transparent;
  box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  outline: none;
  font-family: "Noto Sans", sans-serif;
  font-size: 14px;
  text-align: left;
  text-overflow: ellipsis;
  line-height: 36px;
  -webkit-appearance: none;
}