.tail-text {
  font-size: 13px;
  color: white;
  text-decoration: none;
  font-weight: bold;
  margin: 0 12px;
}
.tail-text1 {
  font-size: 13px;
  color: navy;
  text-decoration: none;
  font-weight: bold;
  border-radius: 12px;
  padding: 12px 8px;
  background: white;
}

.search-icon {
  display: flex;
  background-color: white;
  border: 1px solid white;
  padding: 2px 23px;
  border-radius: 5px;
  align-items: center;
}

.in-seaarch {
  padding: 10px 23px;
  color: black;
  font-size: 15px;
  outline: 0;
}
.headers .bg-white {
  /* background-color: #3b6f7c !important; */
}
.headers .nav-link {
  /* margin: 0px 20px; */
  margin: 0px 10px;
  /* color: black; */
  color: white;
  /* font-size: 20px; */
  font-size: 14px;
  font-weight: 600;
  /* font-family: "Piazzolla", serif; */
}

.top-nav-header {
  width: 100%;
  height: 6rem;
  display: flex;
  padding: 0 100px;
  justify-content: space-between;
}

.top-nav {
  display: flex;
  float: left;
}

.navbar-left-content {
  display: flex;
  float: right;
  padding: 12px 0;
}
.col-sm-12 {
  display: flex;
  gap: 12px;
}
.top-nav-icon {
  margin: 10px 0;
}

.top-nav-icon-content {
  padding: 10px 12px 0 0px;
}
.top-nav-icon-content p {
  margin: 0;
  padding: 0;
  text-align: right;
  text-align: right;
  font-size: 12px;
  line-height: 1.375;
}

.navbar-right-content{
  display: flex;
  padding: 5px 0;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  height: 7rem;
  width: 8rem;
  padding: 5px 0 !important;
  margin: 0px !important;
  position: absolute;
  background-color: white;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  color: black;
  left: -10px;
}

.dropdown:hover .dropdown-content {
  display: block;
  padding: 0;
  margin: 0;
  text-align: center;
}

.dropdown-content a {
  color: black;
  text-decoration: none;
}

.active-link {
  color: red;
  text-decoration: underline;
  /* Add any other styles for the active link here */
}
.jsdf{
  align-items: center;
    gap: 10px;
}

@media screen and (max-width:799px) {
  .top-nav-header{
 display: none;
}  
.navbar-right-content{
  text-align: center;
  display: block;
}
.jsdf{
  align-items: center;
    gap: 10px;
    margin: 10px 0;
}
.tail-text{
  color: navy;
}
.dropdown-content{
  left: 35px !important;
  position: absolute;
}
.tail-text1{
  color: white;
    border-radius: 12px;
    padding: 8px 8px;
    background: navy;
}
.relative.inline-flex span {
  position: absolute;
  background-color: red;
  border-radius: 100%;
  height: 21px;
  width: 21px;
  top: 200px;
  right: 155px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2px;
}
}
  