.footer {
  position: relative;
  padding-top: 75px;
  border-top: 1px solid lightgray;
  margin:10px 0;
}
.ocean {
  height: 86px; /* change the height of the waves here */
  width: 100%;
  position: absolute;
  bottom: -26px;
  left: 0;
  right: 0;
  overflow-x: hidden;
}
.pro-links li a:hover{
 color:red
}
.wave {
  background-image: url("../../public/images/wave-img.svg");
  position: absolute;
  width: 200%;
  height: 100%;
  animation: wave 10s -3s linear infinite;
  transform: translate3d(0, 0, 0);
  opacity: 0.8;
}

.wave:nth-of-type(2) {
  bottom: 0;
  animation: wave 18s linear reverse infinite;
  opacity: 0.5;
}

.wave:nth-of-type(3) {
  bottom: 0;
  animation: wave 20s -1s linear infinite;
  opacity: 0.5;
}
@keyframes wave {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-25%);
  }
  100% {
    transform: translateX(-50%);
  }
}
.pro-links li a {
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
  color: black;
}
